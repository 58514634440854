import React from "react";
import Menu from "../../components/menu/Menu";
import Projects from "../../components/projects/Projects";

const Portfolio = () => {
  return (
    <>
      <Menu />
      <div className="portfolio">
        <Projects />
      </div>
    </>
  );
};

export default Portfolio;
