import React from "react";
import "./Specialization.css";
import { SpecializationData } from "../../data/Data";

export default function Specialization() {
  return (
    <div>
      <h1 style={{ textAlign: "center", fontSize: "40px", margin: "2rem" }}>
        Our Specialization
      </h1>
      <div className="specialization">
        {SpecializationData.map((card) => {
          return (
            <div className="specialization-card">
              <img
                src={card.img}
                style={{ height: "50px", width: "50px", padding: "5px" }}
              ></img>
              <h2>{card.title}</h2>
              <p>{card.description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
