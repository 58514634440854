import React from "react";
import "./Process.css";
import { FrontendProcessData } from "../../data/ProcessData";
const FrontendProcess = (props) => {
  return (
    <div className="process">
      <h1 style={{ textAlign: "center", fontSize: "40px", margin: "4rem" }}>
        Our Frontend Development <br /> Process
      </h1>

      <div className="process-card-container">
        {FrontendProcessData.map((card) => {
          return (
            <div className="process-card">
              <img
                src={card.img}
                style={{ height: "50%", width: "40%", padding: "35px" }}
              />
              <h5>{card.title}</h5>
              <p>{card.description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FrontendProcess;
