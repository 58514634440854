import React from "react";
import AboutBanner from "../../components/about/AboutBanner";
import Footer from "../../components/footer/Footer";
import Menu from "../../components/menu/Menu";
import Promise from "../../components/promise/Promise";
import Specialization from "../../components/specialization/Specialization";
import Testimonials from "../../components/testimonials/Testimonials";

const About = () => {
  return (
    <div>
      <Menu />
      <AboutBanner />
      <Specialization />
      <Promise />
      <Testimonials />
      <Footer />
    </div>
  );
};

export default About;
