import React from "react";

import SliderComponent from "../../components/slider/SliderComponent";

import "./webPage.css";
import { Link } from "react-router-dom";

import WebMain from "../../assets/WebMain.png";

import Menu from "../../components/menu/Menu";
import Footer from "../../components/footer/Footer";
import { FrontendServices } from "../../components/frontendservices/FrontendServices";
import FrontendProcess from "../../components/process/FrontendProcess";
import Team from "../../components/team/Team";

const WebPage = () => {
  return (
    <>
      <Menu />
      <section className="showcase webpage container">
        {/* left section */}
        <div className="left">
          <h1>
            Web Development <span className="next">Services Delivering</span>
            <span className="next">Adaptable Interfaces</span>
            <span className="next">For All Screen Sizes</span>
          </h1>
          <p className="light">
            We provide reliable and adaptable web app solutions without
            compromising your commercial goals.
          </p>

          <button className="touch btn">
            <Link to="/contact">Let's Connect</Link>
          </button>
        </div>

        <div className="right">
          <img
            src={WebMain}
            style={{
              borderRadius: "30px",
              marginTop: "-50px",
              width: "1500px",
            }}
            alt="Web Main Image"
          />
        </div>
      </section>

      <FrontendServices />
      <h1 style={{ textAlign: "center", fontSize: "40px", marginTop: "30px" }}>
        Projects
      </h1>
      <SliderComponent />
      <FrontendProcess />
      <Team />
      <Footer />
    </>
  );
};

export default WebPage;
