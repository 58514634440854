import React from "react";
import Form from "../../components/form/Form";
import Menu from "../../components/menu/Menu";

const Contact = () => {
  return (
    <div>
      <Menu />
      <Form />
    </div>
  );
};

export default Contact;
