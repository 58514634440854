import React from "react";
import "./Testimonials.css";
import Avatar from "../../assets/Avatar.svg";
import Stars from "../stars/Stars";
import Avatar2 from "../../assets/Avatar2.svg";
import Avatar3 from "../../assets/Avatar3.svg";
import Avatar4 from "../../assets/Avatar4.svg";

const Testimonials = () => {
  return (
    <div className="testimonial-container">
      <h1 style={{ textAlign: "center", fontSize: "40px", marginTop: "20px" }}>
        Testimonials
      </h1>
      <div className="testimonials">
        <div className="testimonial-card">
          <img
            src={Avatar}
            style={{ height: "100px", width: "150px", padding: "5px" }}
          ></img>
          <h1>James Safford</h1>
          <Stars />
          <p>
            I was really impressed by the software developed by this company. It
            was intuitive, easy to use, and made my work much more efficient. I
            would definitely recommend them to anyone looking for software
            solutions.
          </p>
        </div>
        <div className="testimonial-card">
          <img
            src={Avatar2}
            style={{ height: "100px", width: "150px", padding: "5px" }}
          ></img>
          <h1>Hassan Rashid</h1>
          <Stars />
          <p>
            The team at this software company is top-notch. They were responsive
            to all of my questions and concerns, and delivered a high-quality
            product on time and within budget. I would definitely work with them
            again
          </p>
        </div>
   {/*      <div className="testimonial-card">
          <img
            src={Avatar3}
            style={{ height: "100px", width: "150px", padding: "5px" }}
          ></img>
          <h1>Marius Harca</h1>
          <Stars />
          <p>
            I've tried several services in the past, but none have been as
            reliable and effective as the ones developed by this company. Their
            team is knowledgeable and dedicated to their craft, and it shows in
            the quality of their work.
          </p>
        </div> */}
        <div className="testimonial-card">
          <img
            src={Avatar4}
            style={{ height: "100px", width: "150px", padding: "5px" }}
          ></img>
          <h1>Jessica Smith</h1>
          <Stars />
          <p>
            This software company exceeded my expectations in every way. They
            were able to take my vision and turn it into a functional and
            user-friendly product that has been a game-changer for my business.
            I would highly recommend their services to anyone.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
