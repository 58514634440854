import React from 'react';

import './slider.css';

const Slider = (props) => {
  const { heading, paragraph, image } = props;

  return (
    <section className='container slider-main'>
      <div className='slider-left'>
        <img src={image} alt='project images' />
        <p>{paragraph}</p>
      </div>
    </section>
  );
};

export default Slider;
