import React from "react";
import Star from "../../assets/Star.svg";
import "./Stars.css";

const Stars = () => {
  return (
    <div className="stars">
      <img
        src={Star}
        style={{ height: "35px", width: "50px", padding: "5px" }}
      ></img>
      <img
        src={Star}
        style={{ height: "35px", width: "50px", padding: "5px" }}
      ></img>
      <img
        src={Star}
        style={{ height: "35px", width: "50px", padding: "5px" }}
      ></img>
      <img
        src={Star}
        style={{ height: "35px", width: "50px", padding: "5px" }}
      ></img>
      <img
        src={Star}
        style={{ height: "35px", width: "50px", padding: "5px" }}
      ></img>
    </div>
  );
};

export default Stars;
