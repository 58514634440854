import React from "react";
import "./FrontendServices.css";
import { FrontendProcessData } from "../../data/Data";

export function FrontendServices() {
  return (
    <>
      <h1 style={{ textAlign: "center", fontSize: "40px", margin: "2rem" }}>
        Our Front End Development Services
      </h1>
      <div className="frontendservices">
        {FrontendProcessData.map((card) => {
          return (
            <div className="frontendservices-card">
              <img
                src={card.img}
                style={{ height: "50px", width: "50px", padding: "5px" }}
              ></img>
              <h1>{card.title}</h1>
              <p>{card.description}</p>
            </div>
          );
        })}
      </div>
    </>
  );
}
