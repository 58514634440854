import "./App.css";
import HomePage from "./pages/home/HomePage";
import WebPage from "./pages/web/WebPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MobilePage from "./pages/mobile/MobilePage";
import About from "./pages/about/About";
import Portfolio from "./pages/portfolio/Portfolio";
import Contact from "./pages/contact/Contact";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/web" element={<WebPage />} />
          <Route path="/mobile" element={<MobilePage />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
      {/*   <HomePage />
      <h1>Below is Web Page</h1>
      <WebPage /> */}
    </div>
  );
}

export default App;
