import React from "react";

import "./projects.css";

import Project1 from "../../assets/project1.png";
import Project2 from "../../assets/project2.png";

import Project3 from "../../assets/project4.png";
import Project4 from "../../assets/project5.png";
import Project5 from "../../assets/project6.png";
import Hypesip from "../../assets/Hypesip.png";
import Cuppary from "../../assets/Cuppary.png";
import Igifty from "../../assets/Igifty.png";

const Projects = () => {
  return (
    <>
      <h1 id="projects" style={{ textAlign: "center", fontSize: "40px", margin: "4rem" }}>
        Our Projects
      </h1>
      <section className="container projects">
        <div className="image-1 img">
          <img src={Project1} alt="" />
          <div className="description">
            <h3>Online Learning Platform</h3>
            <button className="touch btn">
              <a href="https://webb-learn.netlify.app/" target="_blank">
                visit now
              </a>
            </button>
          </div>
        </div>
        <div className="image-2 img">
          <img src={Project2} alt="" />
          <div className="description">
            <h3>Bookmark Landing Page</h3>
            <button className="touch btn">
              <a href="https://bookmark-landing.netlify.app/" target="_blank">
                visit now
              </a>
            </button>
          </div>
        </div>
        <div className="image-3 img">
          <img src={Project3} alt="" />
          <div className="description">
            <h3>AppLand Landing Page</h3>
            <button className="touch btn">
              <a href="https://applicationland.netlify.app/" target="_blank">
                visit now
              </a>
            </button>
          </div>
        </div>
        <div className="image-4 img">
          <img src={Project4} alt="" />
          <div className="description">
            <h3>Students Record Management</h3>
          </div>
        </div>
        <div className="image-5 img">
          <img src={Project5} alt="" />

          <div className="description">
            <h3>eCommerce Dashoboard</h3>
            <button className="touch btn">
              <a
                href="https://ecommerce-dashbaord.netlify.app/"
                target="_blank"
              >
                visit now
              </a>
            </button>
          </div>
        </div>

        <div className="image-6 img">
          <img src={Hypesip} alt="" />

          <div className="description">
            <h3>eCommerce Store</h3>
            <button className="touch btn">
              <a href="https://hypesip.com/" target="_blank">
                visit now
              </a>
            </button>
          </div>
        </div>

        <div className="image-7 img">
          <img src={Cuppary} alt="" />

          <div className="description">
            <h3>Electric Cupping Store</h3>
            <button className="touch btn">
              <a href="https://cuppary.com/" target="_blank">
                visit now
              </a>
            </button>
          </div>
        </div>

        <div className="image-8 img">
          <img src={Igifty} alt="" />

          <div className="description">
            <h3>Necklace Gift Store</h3>
            <button className="touch btn">
              <a href="https://i-gifty.com/" target="_blank">
                visit now
              </a>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Projects;
