import React from "react";
import "./Footer.css";
import Gmail from "../../assets/Gmail.svg";

const Footer = () => {
  return (
    <div className="footer">
      <div className="about">
        <h1>About</h1>
        <p>
          SOFT ÉIREANN brings to you a strong team comprising of creative and
          tech savvy engineers and strategists who dedicate their time and
          efforts towards the digital evolution of your mobile and web products.
        </p>
      </div>
      <div className="services">
        <h1>Services</h1>

        <p>UI/UX Design</p>
        <p>Web Development</p>
        <p>Mobile App Development</p>
      </div>
      <div className="contact">
        <h1>Contact</h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            src={Gmail}
            style={{
              height: "30px",
              width: "30px",
              marginRight: "10px",
              marginTop: "10px",
            }}
          />
          <p>support@softeireann.com</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
