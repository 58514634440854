import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Slider from './Slider';

// project images
import Project1 from '../../assets/project1.png';
import Project2 from '../../assets/project2.png';
import Project3 from '../../assets/project4.png';
import Project4 from '../../assets/project5.png';
import Project5 from '../../assets/project6.png';

// responsive breakpoints
const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

// paragraphs
const paragraphOne =
  'increased user engagement, useability, adaptibility while providing modern user experience (UX)';
const paragraphTwo =
  'A clean and simple interface to organize your favourite websites. Open a new browser tab and see your sites load instantly.';
const paragraphThree =
  'Launch your apps with confidence and creativity.Try it for free.';
const paragraphFour =
  'An optimized students record management system, mongodb and nodejs at the back';
const paragraphFive =
  'An optimized eccommerce record management system, mongodb and nodejs at the back';

const items = [
  <div className='item' data-value='1'>
    <Slider paragraph={paragraphOne} image={Project1} />
  </div>,
  <div className='item' data-value='2'>
    <Slider paragraph={paragraphTwo} image={Project2} />
  </div>,
  <div className='item' data-value='3'>
    <Slider paragraph={paragraphThree} image={Project3} />
  </div>,
  <div className='item' data-value='4'>
    <Slider paragraph={paragraphFour} image={Project4} />
  </div>,
  <div className='item' data-value='5'>
    <Slider paragraph={paragraphFive} image={Project5} />
  </div>,
];

const SliderComponent = () => {
  return (
    <section className='container'>
      <AliceCarousel
        // infinite loop
        autoPlay
        autoPlayControls
        autoPlayStrategy='none'
        autoPlayInterval={1000}
        animationDuration={1000}
        infinite
        mouseTracking
        items={items}
        responsive={responsive}
        controlsStrategy='alternate'
      />
    </section>
  );
};

export default SliderComponent;
