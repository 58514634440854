import React from "react";
import "./Promise.css";
import { PromiseData } from "../../data/Data";

export default function Promise() {
  return (
    <>
      <h1 style={{ textAlign: "center", fontSize: "40px", margin: "2rem" }}>
        Our Promise
      </h1>
      <div className="promises">
        {PromiseData.map((card) => {
          return (
            <div className="promises-card">
              <img
                src={card.img}
                style={{ height: "60px", width: "100px", padding: "5px" }}
              ></img>
              <h1>{card.title}</h1>
              <p>{card.description}</p>
            </div>
          );
        })}
      </div>
    </>
  );
}
