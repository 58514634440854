import React, { useRef } from "react";
import "./menu.css";

const Menu = () => {
  const hamburgerMenu = useRef();

  const openMenu = (e) => {
    console.log(hamburgerMenu.current);
    hamburgerMenu.current.classList.toggle("active");
  };

  return (
    <header className="header container ">
      {/* container has been removed */}
      <div className="logo">
        <h1 style={{ fontSize: "40px" }}>
          SOFT<span style={{ color: "rgb(206, 45, 20)" }}> ÉIREANN</span>
        </h1>
      </div>

      <nav className="navbar">
        <div className="hamburger" onClick={(e) => openMenu(e)}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>

        <div className="menu" ref={hamburgerMenu}>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/web">Web</a>
            </li>
            <li>
              <a href="/mobile">Mobile</a>
            </li>
            <li>
              <a href="/portfolio">Portfolio</a>
            </li>
            <li>
              <a href="/about">About Us</a>
            </li>

            <button className="touch btn">
              <a href="/contact">Get in touch</a>
            </button>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Menu;
