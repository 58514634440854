import React from "react";

import Menu from "../../components/menu/Menu";
import Showcase from "../../components/showcase/Showcase";
import Projects from "../../components/projects/Projects";

import "./home.css";
import Specialization from "../../components/specialization/Specialization";
import Process from "../../components/process/Process";
import Team from "../../components/team/Team";
import Footer from "../../components/footer/Footer";
import Testimonials from "../../components/testimonials/Testimonials";

const HomePage = () => {
  return (
    <section className="home">
      <Menu />
      <Showcase />
      <Specialization />
      <Projects />
      <Process />
      <Testimonials />
      <Team />
      <Footer />
    </section>
  );
};

export default HomePage;
