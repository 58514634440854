import React from "react";
import "./AboutBanner.css";

const AboutBanner = () => {
  return (
    <div>
      <section className="hero">
        <div className="content">
          <h2>Helping Brands</h2>
          <h2> Create a Digital Presence</h2>
          <p>Our skilled and experienced developers are good at what they do</p>
          <p>
            and focused on developing apps which can leave a mark in the tech
            industry 😎
          </p>
        </div>
        <div class="waves"></div>
      </section>
      <div
        className="storymission"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          margin: "0 100px",
        }}
      >
        <h1 style={{ fontSize: "40px", margin: "10px" }}>Our Story</h1>
        <p>
          Our design and development engineers are focused on delivering
          products that equip our clients to dominate their respective
          industries. The app development team at Softeireann makes use of
          integrated skill set, which results in the creation of enthralling
          user experience and an intricate technology framework. We want to
          enable entrepreneurs, digital agencies, and businesses to build,
          improve, and expand globally.
        </p>
        <br />
        <p>
          When you sign up with us, you will be delivered proven processes that
          guarantee success. For us, our projects are like partnerships and not
          mere transactions. We incorporate the latest advanced technologies to
          create a product that can help you gain a competitive advantage.
        </p>
        <br />
        <p>
          Our app development process incorporates top-class technique, clean
          UX/UI structure, and vigorous advancement rehearses. Team Softeireann
          attempts to develop apps that outperform your competitor’s apps
          alongside providing specialized consultancy.
        </p>
        <br />
        <h1 style={{ fontSize: "40px", margin: "10px" }}>Mission & Vision</h1>
        <p>
          Softeireann is a team of highly proficient app developers, passionate
          UX/UI designers, focused solution architects, and creative digital
          product developers who are habitual of exceeding client’s
          expectations. Our solutions are tailor-made to cater to your business
          needs. Be it short-term management or a long-term commitment, team
          Softeireann build apps and solutions empowered by vision, expanded
          technical scope, stunning design, engaging UI and robust backend
          development.
        </p>
        <br />
      </div>
    </div>
  );
};

export default AboutBanner;
