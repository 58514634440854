/* import img1 from "../imgs/img1.png" */

/* export const SidebarData = [ {
    img: img1,
    name:"Andrew Tate",
    notification:"Gets a new buggati",
    time: "25 Minutes ago",
},
]
 */

/* This is SPECIALIZATION DATA */

import FIGMA from "../assets/figma.svg";
import FLUTTER from "../assets/Flutter.svg";
import WORDPRESS from "../assets/wordpress.svg";
import WEB from "../assets/web.svg";
import REACT from "../assets/React.svg";
import BOOTSTRAP from "../assets/Bootstrap.svg";
import HONESTY from "../assets/Honesty.svg";
import INTEGRITY from "../assets/Integrity.svg";
import QUALITY from "../assets/GoodQuality.svg";
import ACCOUNTABLE from "../assets/Accountable.svg";
import PASSION from "../assets/Passion.svg";

export const SpecializationData = [
  {
    img: FIGMA,
    title: "Figma Design",
    description:
      "Create pixel-perfect interfaces with vibrant colors are used to blend with your branding, portraying an impressive first impression to users.",
  },
  {
    img: FLUTTER,
    title: "Flutter",
    description:
      "Flutter being Cross Platform, you can simultaneously reach many users across different operating systems by developing a single product.",
  },
  {
    img: WORDPRESS,
    title: "Wordpress",
    description:
      "Wordpress being a flexible CMS. We aim to deliver the best-in-class and feature-packed WordPress-oriented websites.",
  },
  {
    img: WEB,
    title: "Custom Web",
    description:
      "Utilising ReactJS, we create responsive and content-rich web applications that are fast-loading, extensible and reusable.",
  },
];

export const FrontendProcessData = [
  {
    img: REACT,
    title: "ReactJS Front End Development",
    description:
      "Features like efficiency and scalability go hand in hand with ReactJS. We create responsive and content-rich web applications that are fast-loading, extensible and reusable.",
  },
  {
    img: FLUTTER,
    title: "Flutter for Front End Development",
    description:
      "Keeping your pace with the fast moving tech-world is a necessity. Our front end developers use Flutter to build high-performance, fast and beautiful mobile apps for all the platforms.",
  },
  {
    img: BOOTSTRAP,
    title: "Bootstrap CSS Framework",
    description:
      "To best fit with the branding guidelines a consistent UI in terms of colors, fonts and design play a vital role. Our Front end developers are expert at crafting responsive websites.",
  },
];

export const PromiseData = [
  {
    img: HONESTY,
    title: "Honesty",
    description:
      "From project initiation until its completion and delivery, we ensure transparency throughout the process. We set realistic milestones, and they are communicated to our clients.",
  },
  {
    img: INTEGRITY,
    title: "Integrity",
    description:
      "Treating everyone right is one of the most active virtues incorporated in our working culture, and this practice lays the ground of satisfactory client management.",
  },
  {
    img: QUALITY,
    title: "Quality",
    description:
      "Our prime focus is always on what unique value we can add in your idea, how we can create an edge and to make it to the app stores.",
  },
  ,
  {
    img: ACCOUNTABLE,
    title: "Accountability",
    description:
      "It is of high importance that we recognize wrong as wrong and this is what we have done from the very start. We own our failures and provide solutions for them at our cost.",
  },
  ,
  {
    img: PASSION,
    title: "Passion",
    description:
      "Our team is full of technology enthusiasts who love simplifying complex ideas into winning viable digital products & solutions.",
  },
];
