import React from "react";

import Laptop from "../../assets/Laptop.png";
import { Link } from "react-router-dom";

const Team = () => {
  return (
    <section className="showcase webpage container">
      {/* left section */}
      <div className="left">
        <h1>Do You Need a Dedicated Team of Developers?</h1>
        <p className="light">
          You are at the right spot!! We engage our experienced team to make
          your dream come true. Contact us so we can get started.
        </p>

        <button className="touch btn">
          {/*    <a href="/contact">Let's Connect</a> */}
          <Link to="/contact">Let's Connect</Link>
        </button>
      </div>

      <div className="right">
        <img src={Laptop} alt="Laptop" style={{ marginTop: "-100px" }} />
      </div>
    </section>
  );
};

export default Team;
