/* import React from "react";
import "./Process.css";
import NEED from "../../assets/need.svg";
import WORK from "../../assets/work.svg";
import DETAILS from "../../assets/details.svg";

import GATHER from "../../assets/gather.svg";
import PLAN from "../../assets/plan.svg";
import DESIGN from "../../assets/design.svg";
import DEVELOP from "../../assets/develop.svg";
import TEST from "../../assets/test.svg";
import FINAL from "../../assets/final.svg";

function Process() {
  return (
    <div className="process">
      <h1 style={{ textAlign: "center", fontSize: "40px", margin: "4rem" }}>
        Our Project Management <br /> Process
      </h1>
      <div className="process-card-container">
        <div className="process-card">
          <img src={GATHER} />
          <h5>1. Gathering Requirements</h5>
          <p>
            Understanding and documenting your requirements is our utmost
            priority.
          </p>
        </div>
        <div className="process-card">
          <img src={PLAN} />
          <h5>2. Creating A Plan of Action</h5>
          <p>
            Our services are built around well-defined project planning metrics
            so we leave nothing for assumption. We lay out every step and stage
            so anything that proceeds further is defined in detailed technical
            specifications, devised and designed on your specific branding
            guidelines. Once we get a confirmation from you, the planning starts
            to materialize.
          </p>
        </div>
        <div className="process-card">
          <img src={DESIGN} />
          <h5>3. The Design Process</h5>
          <p>
            Our design stage includes all kinds of Web, Mobile-specific, and
            Tablet UI wireframes designed according to latest standards and your
            specific guidelines. We value trust more than anything, and
            therefore produce clickable prototypes to help you get a clear
            picture first. All deliverables are shared on Invision platform so
            you may access the material.
          </p>
        </div>
        <div className="process-card">
          <img src={DEVELOP} />
          <h5>4. The Development Phase</h5>
          <p>
            This is one of the most crucial stages, where we transform ideas
            into reality. Here, all your requirements for frontend, backend, web
            services, and API development integration are coded. Using Agile
            Scrum methodology, we factor all the aspects of scalability,
            multi-tenancy, and 3rd party integration to craft an optimized clean
            code structure using cutting-edge technologies.
          </p>
        </div>
        <div className="process-card">
          <img src={TEST} />
          <h5>5. Product Quality Testing</h5>
          <p>
            Each sprint is manually tested and all bugs are reported back to the
            development team. Before the final release, we make sure everything
            checks out so get a completely glitch free source code. Our QA team
            applies regression testing techniques to make sure all elements are
            properly functioning based on the milestones/sprints that were
            approved by you.
          </p>
        </div>
        <div className="process-card">
          <img src={FINAL} />
          <h5>6. Our Work Doesn’t End Here</h5>
          <p>
            We always strive to go the extra mile for our clients. Through an
            agile approach, we take all the necessary steps to ensure the
            product remains functional and up to date. This involves routine
            bi-weekly code backups, security audits, periodic system
            upgradation, and marketing revamps. We test the entire system each
            month.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Process;
 */

import React from "react";
import "./Process.css";
import { HomeProcessData } from "../../data/ProcessData";
const Process = (props) => {
  return (
    <div className="process">
      <h1 style={{ textAlign: "center", fontSize: "40px", margin: "4rem" }}>
        Our Project Management <br /> Process
      </h1>

      <div className="process-card-container">
        {HomeProcessData.map((card) => {
          return (
            <div className="process-card">
              <img
                src={card.img}
                style={{ height: "140px", width: "60%", padding: "35px" }}
              />
              <h5>{card.title}</h5>
              <p>{card.description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Process;
