import React from 'react';

import './showcase.css';

import stats from '../../assets/stats.svg';

const Showcase = () => {
  return (
    <section className='showcase container'>
      {/* left section */}
      <div className='left'>
        <h4>Your Products Growth Solution in Single Platform</h4>
        <h1>
          Give Your Business a <span className='next'>Digital Presence</span>
        </h1>
        <p className='light'>
          Empower your business and turn ideas into great reality
        </p>

        <button className='touch btn'>
          <a href='#projects'>View Projects</a>
        </button>
      </div>

      <div className='right'>
        <img src={stats} alt='Statistics' />
      </div>
    </section>
  );
};

export default Showcase;
