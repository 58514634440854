import React from "react";

import SliderComponent from "../../components/slider/SliderComponent";

import "./mobilePage.css";
import { Link } from "react-router-dom";

import Mobile from "../../assets/MobileMain.png";

import Menu from "../../components/menu/Menu";
import Footer from "../../components/footer/Footer";
import Team from "../../components/team/Team";
import MobileProcess from "../../components/process/MobileProcess";

const MobilePage = () => {
  return (
    <>
      <Menu />
      <section className="showcase mobilepage container">
        {/* left section */}
        <div className="left">
          <h1>
            Cross Platform Development{" "}
            <span className="next">Services Delivering</span>
            <span className="next">High Quality</span>
            <span className="next">App Development</span>
          </h1>
          <p className="light">
            We offer customized, user-friendly, and high-quality cross platform
            application development services. Our team helps companies engage
            their potential users across various platforms and devices by
            delivering robust hybrid mobile applications
          </p>

          <button className="touch btn">
            <Link to="/contact">Let's Connect</Link>
          </button>
        </div>

        <div className="right">
          <img
            src={Mobile}
            style={{
              borderRadius: "30px",
              marginTop: "-40px",
              width: "1500px",
              height: "570px",
            }}
            alt="Mobile Image Main"
          />
        </div>
      </section>

      <h1 style={{ textAlign: "center", fontSize: "40px", margin: "30px" }}>
        Projects
      </h1>
      <SliderComponent />
      <MobileProcess />
      <Team />
      <Footer />
    </>
  );
};

export default MobilePage;
