import React from "react";
import "./Form.css";

const Form = () => {
  return (
    <div className="form-body">
      <form class="form">
        <div class="title">Contact Us</div>
        <div class="input-container ic1">
          <input id="name" class="input" type="text" placeholder=" " required />
          <div class="cut"></div>
          <label for="name" class="placeholder">
            Name
          </label>
        </div>

        <div class="input-container ic1">
          <input
            id="email"
            class="input"
            type="email"
            placeholder=" "
            required
          />
          <div class="cut"></div>
          <label for="email" class="placeholder">
            Email
          </label>
        </div>

        <div class="input-container ic1">
          <input id="phone" class="input" type="number" placeholder=" " />
          <div class="cut long"></div>
          <label for="phone" class="placeholder">
            Phone Number
          </label>
        </div>

        <div class="input-container ic1">
          <input id="message" class="input" type="text" placeholder=" " />
          <div class="cut"></div>
          <label for="lastname" class="placeholder">
            Message
          </label>
        </div>

        <button type="submit" class="submit">
          Submit
        </button>
      </form>
    </div>
  );
};

export default Form;
