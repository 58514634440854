import React from "react";

import GATHER from "../assets/Gather.svg";
import CREATE from "../assets/Create.svg";
import DESIGN from "../assets/Design.svg";
import DEVELOPMENT from "../assets/Development.svg";
import TESTING from "../assets/Testing.svg";
import SUPPORT from "../assets/Support.svg";
import PROTOTYPE from "../assets/Prototype.svg";
import AGILE from "../assets/Agile.svg";
import RELEASE from "../assets/Release.svg";
import USECASE from "../assets/UseCase.svg";
import QUALITY from "../assets/Quality.svg";

export const HomeProcessData = [
  {
    img: GATHER,
    title: "1. Gather Requirements",
    description:
      "Understanding and documenting your requirements is our utmost priority.",
  },
  {
    img: CREATE,
    title: "2. Create Plan of Action",
    description:
      "Our services are built around well-defined project planning metrics so we leave nothing for assumption.",
  },
  {
    img: DESIGN,
    title: "3. Design Process",
    description:
      "Our design stage includes all kinds of Web, Mobile-specific, and Tablet UI wireframes designed according to latest standards and your specific guidelines.",
  },
  {
    img: DEVELOPMENT,
    title: "4. Development Process",
    description:
      "This is one of the most crucial stages, where we transform ideas into reality. All requirements are coded.",
  },
  {
    img: TESTING,
    title: "5. Quality Testing",
    description:
      "Each sprint is manually tested and all bugs are reported back to the development team.",
  },
  {
    img: SUPPORT,
    title: "6. Support",
    description:
      "Through an agile approach, we take all the necessary steps to ensure the product remains functional and up to date.",
  },
];

export const FrontendProcessData = [
  {
    img: GATHER,
    title: "1. Project Requirements",
    description:
      "Client Support team assigns one member to gather project requirements.",
  },
  {
    img: PROTOTYPE,
    title: "2. Prototyping",
    description:
      "Next is wireframing and prototyping so that the client gets an idea of how the screens will appear.",
  },
  {
    img: USECASE,
    title: "3. Use Cases",
    description:
      "Project features and functionality are drafted in a use case document.",
  },
  {
    img: DESIGN,
    title: "4. Design",
    description:
      "Once the client approves the prototypes, we move on to design the application.",
  },
  {
    img: CREATE,
    title: "5. Project plan",
    description: "The project is managed via project management tools.",
  },
  {
    img: AGILE,
    title: "6. Agile development",
    description:
      "The project goes into development where our development team puts in their dedicated effort.",
  },
  ,
  {
    img: TESTING,
    title: "7. Testing & QA",
    description:
      "Manual & automated QA checks are run post the development phase.",
  },
  ,
  {
    img: RELEASE,
    title: "8. Release",
    description: "The product is made live where intended users can access it.",
  },
  ,
  {
    img: SUPPORT,
    title: "9. Maintenance",
    description:
      "Post app launch as per requirement maintenance, quality support and enhancement services are delivered.",
  },
];

export const MobileProcessData = [
  {
    img: GATHER,
    title: "1. Project Requirements",
    description:
      "Client Support team assigns one member to gather project requirements.",
  },
  {
    img: PROTOTYPE,
    title: "2. Prototyping",
    description:
      "Next is wireframing and prototyping so that the client gets an idea of how the screens will appear.",
  },
  {
    img: DESIGN,
    title: "3. Use Cases",
    description:
      "Project features and functionality are drafted in a use case document.",
  },
  {
    img: DESIGN,
    title: "4. Design",
    description:
      "Once the client approves the prototypes, we move on to design the application.",
  },
  {
    img: TESTING,
    title: "5. Project plan",
    description: "The project is managed via project management tools.",
  },
  {
    img: DEVELOPMENT,
    title: "6. Development Process",
    description:
      "The project goes into development where our development team puts in their dedicated effort.",
  },
  ,
  {
    img: QUALITY,
    title: "7. Quality Assurance",
    description:
      "Manual & automated QA checks are run post the development phase.",
  },
  ,
  {
    img: RELEASE,
    title: "8. Release",
    description: "The product is made live where intended users can access it.",
  },
];
